/* eslint sort-keys: ["error", "asc"] */

class OAuthFlowStart {
  auth_uri: string;
  code_challenge: string | null;
  code_verifier: string | null;
  flow_id: string;
  nonce: string | null;
  operation: string;
  redirect_uri: string;
  scope: string[];
  state: string;
  token_endpoint: string;
  user_type: string;
  end_session_url: string;

  constructor(attrs: Partial<OAuthFlowStart>) {
    Object.assign(this, attrs);
  }

  isChangeOperation(): boolean {
    return this.operation.startsWith("change_");
  }
}

export class OAuthTokenResponse {
  outcomes: string[];
  token_data: OAuthTokenData;

  constructor(attrs: Partial<OAuthTokenResponse>) {
    Object.assign(this, attrs);
  }
}

export class OAuthTokenData {
  id_token: string;
  token_type: string;
  not_before: string;
  id_token_expires_in: string;
  profile_info: string;
  scope: string;

  constructor(attrs: Partial<OAuthTokenData>) {
    Object.assign(this, attrs);
  }
}

export class OAuthFlowState {
  user_type: string;
}

export default OAuthFlowStart;
