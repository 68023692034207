import OAuthFlowStart, { OAuthTokenResponse } from "../models/OAuth";

import BaseApi from "./BaseApi";
import routes from "../routes";

export default class OAuthApi extends BaseApi {
  get basePath() {
    return routes.api.oAuthStartFlow;
  }

  get namespace() {
    return "auth";
  }

  get headers() {
    return [{ featureFlag: "enableUniversalProfilePilot" }];
  }

  getOAuthFlowStart = async (
    authServer: string,
    flow: string,
    language: string
  ) => {
    const { data } = await this.request<OAuthFlowStart>(
      "GET",
      `/${flow}/${authServer}/${language}`,
      undefined,
      {
        excludeAuthHeader: true,
      }
    );
    // console.log(data);

    return Promise.resolve({
      lmgAuth: new OAuthFlowStart(data),
    });
  };
}

export class OAuthTokenApi extends BaseApi {
  get basePath() {
    return routes.api.oAuthToken;
  }

  get namespace() {
    return "auth";
  }

  getOAuthToken = async (
    lmg_auth_uri: OAuthFlowStart,
    auth_code: string,
    state: string
  ) => {
    const auth_code_res = {
      code: auth_code,
      state,
    };
    const requestBody = {
      auth_uri_res: lmg_auth_uri,
      auth_code_res,
    };
    const { data } = await this.request<OAuthTokenResponse>(
      "POST",
      "",
      requestBody,
      {
        excludeAuthHeader: true,
      }
    );

    return Promise.resolve(new OAuthTokenResponse(data));
  };
}
