/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the auth flow.
 */

import routes from "../routes";

export default {
  states: {
    [routes.index]: {
      on: {
        LOG_IN: routes.auth.login,
        LOGGED_IN: routes.applications.index,
        SIGN_UP: routes.auth.chooseAccountType,
      },
    },
    [routes.auth.login]: {
      on: {
        CONTINUE: routes.applications.index,
        ENABLE_MFA: routes.twoFactor.smsIndex,
        FORGOT_PASSWORD: routes.auth.forgotPassword,
        SIGN_UP: routes.auth.chooseAccountType,
        VERIFY_CODE: routes.twoFactor.smsVerify,
        UNCONFIRMED_ACCOUNT: routes.auth.verifyAccount,
        OAUTH_START: routes.auth.oAuthStart,
      },
    },
    [routes.auth.oAuthError]: {
      on: {},
    },
    [routes.auth.oAuthNotice]: {
      on: {
        CONTINUE: routes.index,
      },
    },
    [routes.auth.oAuthLaunch]: {
      on: {
        LOGIN: routes.auth.oAuthStart,
      },
    },
    [routes.auth.oAuthStart]: {
      on: {},
    },
    [routes.auth.oAuthReturn]: {
      on: {
        CONTINUE: routes.index,
        LOGIN: routes.auth.oAuthStart,
        NOTICE: routes.auth.oAuthNotice,
        SETTINGS: routes.user.settings_oauth,
        ERROR: routes.auth.oAuthError,
      },
    },
    [routes.auth.chooseAccountType]: {
      on: {
        LOG_IN: routes.auth.login,
        SIGN_UP_CLAIMANT: routes.auth.createAccount,
        SIGN_UP_EMPLOYER: routes.employers.createAccount,
      },
    },
    [routes.auth.createAccount]: {
      on: {
        CONTINUE: routes.auth.verifyAccount,
        LOG_IN: routes.auth.login,
      },
    },
    [routes.employers.createAccount]: {
      on: {
        CONTINUE: routes.auth.verifyAccount,
        LOG_IN: routes.auth.login,
      },
    },
    [routes.auth.forgotPassword]: {
      on: {
        BACK: routes.auth.login,
        CONTINUE: routes.auth.resetPassword,
      },
    },
    [routes.auth.resetPassword]: {
      on: {
        BACK: routes.auth.login,
        CONTINUE: routes.auth.login,
        VERIFY_EMAIL: routes.auth.verifyAccount,
      },
    },
    [routes.auth.verifyAccount]: {
      on: {
        BACK: routes.auth.login,
        CONTINUE: routes.auth.login,
      },
    },
    [routes.twoFactor.smsVerify]: {
      on: {
        CONTINUE: routes.applications.index,
        LOG_IN: routes.auth.login,
        NO_USER: routes.auth.login,
      },
    },
    [routes.twoFactor.smsIndex]: {
      on: {
        CONTINUE: routes.applications.index,
        EDIT_MFA_PHONE: routes.twoFactor.smsSetup,
      },
    },
    [routes.twoFactor.smsSetup]: {
      on: {
        CONTINUE: routes.twoFactor.smsConfirm,
      },
    },
    [routes.twoFactor.smsConfirm]: {
      on: {
        CONTINUE: routes.applications.index,
        RETURN_TO_SETTINGS: routes.user.settings,
      },
    },
  },
};
