import { isFeatureEnabled } from "src/services/featureFlags";

export default function validateRepeatPassword(
  password?: string,
  repeatPassword?: string
) {
  const enableRepeatPassword = isFeatureEnabled("enableRepeatPassword");
  if (!enableRepeatPassword) {
    return;
  }
  if (!repeatPassword) {
    return {
      field: "repeatPassword",
      type: "required",
      namespace: "auth",
    };
  }
  if (password !== repeatPassword) {
    return {
      field: "repeatPassword",
      type: "mismatched",
      namespace: "auth",
    };
  }
}
