import LeaveAdminsApi, {
  LeaveAdminsAddRequest,
  LeaveAdminsRemoveRequest,
  LeaveAdminsSearchRequest,
} from "../api/LeaveAdminsApi";
import User, { UserLeaveAdministrator } from "../models/User";

import ApiResourceCollection from "../models/ApiResourceCollection";
import { AuthLogic } from "./useAuthLogic";
import { ErrorsLogic } from "./useErrorsLogic";
import { PortalFlow } from "./usePortalFlow";
import { isEqual } from "lodash";
import openIdConnect from "src/services/openIdConnect";
import routes from "../routes";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useLeaveAdminsLogic = ({
  errorsLogic,
  portalFlow,
  auth,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
  auth: AuthLogic;
}) => {
  const leaveAdminsApi = new LeaveAdminsApi();

  /**
   * Track loading state of leave admins data. We store the params that were
   * used to fetch the data, so we can re-fetch when the params change.
   */
  const [isLoadingLeaveAdmins, setIsLoadingLeaveAdmins] = useState<boolean>();
  const [activeParams, setActiveParams] = useState<LeaveAdminsSearchRequest>();

  const { collection: leaveAdmins, setCollection: setLeaveAdmins } =
    useCollectionState(new ApiResourceCollection<UserLeaveAdministrator>("id"));

  const loadAll = async (params: LeaveAdminsSearchRequest) => {
    if (isEqual(activeParams, params) || isLoadingLeaveAdmins) return;
    errorsLogic.clearErrors();
    setIsLoadingLeaveAdmins(true);
    try {
      const { leaveAdmins } = await leaveAdminsApi.list(params);
      setLeaveAdmins(
        new ApiResourceCollection<UserLeaveAdministrator>("id", leaveAdmins)
      );
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setActiveParams(params);
      setIsLoadingLeaveAdmins(false);
    }
  };

  const add = async (requestBody: LeaveAdminsAddRequest) => {
    try {
      await leaveAdminsApi.add(requestBody);
      setActiveParams(undefined); // reset params so leave admin list will display fresh data on next load
      portalFlow.goToPageFor(
        "REDIRECT",
        {},
        {
          employer_id: requestBody.employer_id,
          add: requestBody.email_address,
        },
        { redirect: true }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const remove = async (
    requestBody: LeaveAdminsRemoveRequest,
    queryParams: { employer_id: string; org_name: string; remove: string },
    removingSelf = false
  ) => {
    try {
      await leaveAdminsApi.remove(requestBody);
      if (removingSelf) {
        portalFlow.goToPageFor(
          "REDIRECT_FROM_SELF_REMOVAL",
          {},
          { remove: queryParams.remove, org_name: queryParams.org_name },
          { redirect: true }
        );
      } else {
        setActiveParams(undefined); // reset params so leave admin list will display fresh data on next load
        portalFlow.goToPageFor("REDIRECT", {}, queryParams, { redirect: true });
      }
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Check if current user is MFA verified and redirect to MFA setup if they are not
   * @param user User
   * @param next Route for redirect back to previous location after login
   */
  const checkAndRedirectForNonMFAEmployer = async (
    user: User,
    next = "",
    query = {}
  ) => {
    if (!openIdConnect.isOIDCAuthenticated()) {
      const hasVerifiedMFA = await auth.isPhoneVerified();
      const hasMFAEnabled = user.mfa_delivery_preference === "SMS";
      if (!hasVerifiedMFA || !hasMFAEnabled) {
        portalFlow.goTo(
          routes.twoFactor.smsSetup,
          { next, ...query },
          { redirect: true }
        );
      }
    }
  };

  return {
    add,
    checkAndRedirectForNonMFAEmployer,
    isLoadingLeaveAdmins,
    leaveAdmins,
    loadAll,
    remove,
    setActiveParams,
  };
};

export default useLeaveAdminsLogic;
export type LeaveAdminsLogic = ReturnType<typeof useLeaveAdminsLogic>;
