import { Auth, CognitoUser } from "@aws-amplify/auth";
import {
  CognitoAuthError,
  CognitoError,
  DFPRejectError,
  Issue,
  ValidationError,
  isCognitoError,
} from "../errors";
import {
  FraudDecisionType,
  FraudProtectionType,
} from "src/models/RiskAssessment";
import {
  NullableQueryParams,
  createRouteWithQuery,
} from "../utils/routeWithParams";
import OAuthApi, { OAuthTokenApi } from "src/api/OAuthApi";
import getLoginError, {
  getNotAuthorizedExceptionIssue,
} from "src/utils/getLoginError";
import { useMemo, useState } from "react";

import { ErrorsLogic } from "./useErrorsLogic";
import FraudProtectionApi from "../api/FraudProtectionApi";
import OAuthFlowStart from "../models/OAuth";
import { PortalFlow } from "./usePortalFlow";
import { RoleDescription } from "../models/User";
import UsersApi from "../api/UsersApi";
import { ValuesOf } from "../../types/common";
import assert from "assert";
import combineValidationIssues from "src/utils/combineValidationIssues";
import { isFeatureEnabled } from "../services/featureFlags";
import oidc from "src/services/openIdConnect";
import routes from "../routes";
import tracker from "../services/tracker";
import validateCode from "../utils/validateCode";
import validatePassword from "../utils/validatePassword";
import validateRepeatPassword from "../utils/validateRepeatPassword";
import validateUsername from "../utils/validateUsername";

interface ErrorCodeMap {
  [code: string]: { field?: string; type: string } | undefined;
}

interface MFAChallenge {
  challengeName: string;
  challengeParam: { CODE_DELIVERY_DESTINATION: string };
}
type CognitoMFAUser = CognitoUser & {
  preferredMFA: "NOMFA" | "SMS";
} & MFAChallenge;

const useAuthLogic = ({
  errorsLogic,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const usersApi = useMemo(() => new UsersApi(), []);
  const fraudProtectionApi = useMemo(() => new FraudProtectionApi(), []);
  const oAuthApi = useMemo(() => new OAuthApi(), []);
  const oAuthTokenApi = useMemo(() => new OAuthTokenApi(), []);
  const statusTypes = new Map([
    ["Approve", "Approved"],
    ["Reject", "Rejected"],
  ]);

  // TODO (CP-872): Rather than setting default values for authLogic methods,
  // instead ensure they're always called with required string arguments

  /**
   * Sometimes we need to persist information the user entered on
   * one auth screen so it can be reused on a subsequent auth screen.
   * For these cases we need to store this data in memory.
   * @property authData - data to store between page transitions
   */
  const [authData, setAuthData] = useState({});

  const [cognitoUser, setCognitoUser] = useState<CognitoMFAUser>();

  /**
   * @property isLoggedIn - Whether the user is logged in or not, or null if logged in status has not been checked yet
   */
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  /**
   * Check if the phone number used by the user for MFA has been verified.
   * You can't rely on the presence of MFA preference or the phone number to signify this.
   */
  const isPhoneVerified = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const phone_number_verified = attributes.phone_number_verified;

    tracker.trackEvent("Checked phone_number_verified", {
      // Useful for identifying how common it is for someone to not have
      // a verified phone number on pages where we check this.
      phone_number_verified,
    });

    return phone_number_verified;
  };

  /**
   * Initiate the Forgot Password flow, sending a verification code when user exists.
   * If there are any errors, sets app errors on the page.
   */
  const forgotPassword = async (username: string) => {
    const success = await sendForgotPasswordConfirmation(username);

    if (success) {
      // Store the username so the user doesn't need to reenter it on the Reset page
      setAuthData({ resetPasswordUsername: username });
      portalFlow.goToNextPage();
    }
  };

  /**
   * Initiate the Forgot Password flow, sending a verification code when user exists.
   */
  const resendForgotPasswordCode = async (username: string) => {
    await sendForgotPasswordConfirmation(username);
  };

  /**
   * Initiate the Forgot Password flow, sending a verification code when user exists.
   * @returns Whether the code was sent successfully or not
   */
  const sendForgotPasswordConfirmation = async (username = "") => {
    errorsLogic.clearErrors();
    const trimmedUsername = username.trim();

    const validationIssues = combineValidationIssues(
      validateUsername(trimmedUsername)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return false;
    }

    try {
      tracker.trackAuthRequest("forgotPassword");
      await Auth.forgotPassword(trimmedUsername);
      tracker.markFetchRequestEnd();

      return true;
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return false;
      }

      const authError = getForgotPasswordError(error);
      errorsLogic.catchError(authError);
      return false;
    }
  };

  /**
   * Log in to Portal with the given username (email) and password.
   * If the user has MFA configured, an SMS with a 6-digit verfication code will be sent
   * to the phone number on file in Cognito.
   * If there are any errors, set app errors on the page.
   * @param password Password
   * @param [next] Redirect url after login
   * @param trackingContext context for tracker in new relic for how method is being called
   */
  const login = async (
    username = "",
    password: string,
    deviceContext: string,
    next?: string,
    trackingContext?: string
  ) => {
    errorsLogic.clearErrors();
    const trimmedUsername = username ? username.trim() : "";

    const validationIssues = combineValidationIssues(
      validateUsername(trimmedUsername),
      validatePassword(password)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    try {
      trackingContext === "login" && tracker.trackAuthRequest("signIn");
      const currentUser = await Auth.signIn(trimmedUsername, password);
      setCognitoUser(currentUser);
      tracker.markFetchRequestEnd();

      const enableFraudProtection = isFeatureEnabled("enableFraudProtection");

      if (enableFraudProtection) {
        // Perform Risk Assessment
        const trimmedEmail = username.trim();
        const requestDataLoginAccount = {
          email_address: trimmedEmail,
          device_context_id: deviceContext,
          risk_assessment_type: FraudProtectionType.loginAccount,
          locale: "en",
        };
        const response = await fraudProtectionApi.assessRisk(
          requestDataLoginAccount
        );

        // Report Risk Assessment Status Back To DFP
        if (response?.fraud?.decision) {
          const requestDataLoginAccountStatus = {
            email_address: trimmedEmail,
            device_context_id: deviceContext,
            risk_assessment_type: FraudProtectionType.loginAccountStatus,
            status_type: statusTypes.get(response.fraud.decision),
            reason_type: "None",
            challenge_type: "None",
            login_id: response?.fraud.login_id,
            locale: "en",
          };
          await fraudProtectionApi.assessRisk(requestDataLoginAccountStatus);

          const enableDFPRejectAction = isFeatureEnabled(
            "enableDFPRejectAction"
          );

          if (enableDFPRejectAction && response.fraud.decision === "Reject") {
            if (trackingContext === "changeEmail") {
              /* This tracking context "changeEmail" is actually from confirm password page. 
                 If the DFP call fails there, then the user is logged out.
              */
              await logout({ sessionTimedOut: false, isRedirect: true });
              errorsLogic.catchError(
                new DFPRejectError({
                  type: "dfpRejectAction",
                  namespace: "dfp",
                })
              );
              return;
            }
            await logout({ sessionTimedOut: false, isRedirect: false });
            errorsLogic.catchError(
              new DFPRejectError({ type: "dfpRejectAction", namespace: "dfp" })
            );
            return;
          }
        }
        // Track Event
      }
      const mfaChallenge =
        currentUser.challengeName && currentUser.challengeName === "SMS_MFA";
      const mfaEnabled = currentUser.mfa_delivery_preference;
      trackingContext === "changeEmail" &&
        tracker.trackEvent("Change Email - Verified Password", {
          mfaEnabled: mfaEnabled !== "SMS" ? "false" : "true",
          mfaChallenge: mfaChallenge ? "true" : "false",
        });

      if (mfaChallenge) {
        portalFlow.goToPageFor("VERIFY_CODE", {}, { next });
      } else {
        const apiUser = await usersApi.getCurrentUser();
        const shouldSetMFA = apiUser.user.mfa_delivery_preference === null;
        finishLoginAndRedirect(next, shouldSetMFA);
      }
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }

      if (error.code === "UserNotConfirmedException") {
        portalFlow.goToPageFor("UNCONFIRMED_ACCOUNT");
        return;
      }

      const authError = getLoginError(error);
      errorsLogic.catchError(authError);
    }
  };

  const enableTrustDevice = async () => {
    try {
      await Auth.rememberDevice();
      tracker.trackEvent("trust_device success");
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Verifies the 6-digit MFA code and logs the user into the Portal.
   * If there are any errors, set app errors on the page.
   * @param code The 6-digit MFA verification code
   * @param [next] Redirect url after login
   * @param trackingContext context for tracker in new relic for how method is being called
   */
  const verifyMFACodeAndLogin = async (
    code: string,
    next?: string,
    trustDevice?: boolean,
    trackingContext?: string
  ) => {
    errorsLogic.clearErrors();
    const trimmedCode = code ? code.trim() : "";
    const validationIssues = combineValidationIssues(validateCode(trimmedCode));
    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    try {
      trackingContext === "login" && tracker.trackAuthRequest("confirmSignIn");
      await Auth.confirmSignIn(cognitoUser, trimmedCode, "SMS_MFA");
      tracker.markFetchRequestEnd();
      trackingContext === "login" &&
        tracker.trackEvent("checked trust_device", {
          // Useful for identifying how common it is for someone to trust their device.
          trust_device:
            trustDevice != null ? trustDevice.toString() : "undefined",
        });
      trackingContext === "changeEmail" &&
        tracker.trackEvent("Change Email - Verified MFA Code", {
          mfaEnabled: "true",
        });
      if (trustDevice) {
        enableTrustDevice();
      }
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }
      if (error.message.includes("User temporarily locked.")) {
        errorsLogic.catchError(
          new CognitoAuthError(error, {
            field: "code",
            type: "attemptsExceeded",
            namespace: "auth",
          })
        );
        return;
      }
      errorsLogic.catchError(
        new CognitoAuthError(error, {
          field: "code",
          type: "invalidMFACode",
          namespace: "auth",
        })
      );
      return;
    }
    finishLoginAndRedirect(next);
  };

  /**
   * Log out of the Portal
   * @param options.sessionTimedOut Whether the logout occurred automatically as a result of session timeout.
   */
  const logout = async (
    options = { sessionTimedOut: false, isRedirect: true }
  ) => {
    const { sessionTimedOut } = options;
    let { isRedirect } = options;

    const params: NullableQueryParams = {};
    if (sessionTimedOut) {
      params["session-timed-out"] = "true";
      if (oidc.isOIDCAuthenticated()) {
        // persiste this for the oauth return
        localStorage.setItem("IS_SESSION_TIMEOUT", "true");
      }
    }
    let redirectUrl = createRouteWithQuery(routes.auth.login, params);

    // Set global: true to invalidate all refresh tokens associated with the user on the Cognito servers
    // Notes:
    // 1. This invalidates tokens across all user sessions on all devices, not just the current session.
    //    Cognito currently does not support the ability to invalidate tokens for only a single session.
    // 2. The access token is not invalidated. It remains active until the end of the expiration time.
    //    Cognito currently does not support the ability to invalidate the access token.
    // See also:
    //    - https://dzone.com/articles/aws-cognito-user-pool-access-token-invalidation-1
    //    - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html
    //    - https://github.com/aws-amplify/amplify-js/issues/3435
    try {
      tracker.trackAuthRequest("signOut");
      if (oidc.isOIDCAuthenticated()) {
        const oidcLogoutUrl = oidc.getEndSessionUrl();
        oidc.signOut();
        if (oidcLogoutUrl) {
          // only grab the redirect URL here
          // do the redirect later after tracker actions are complete
          redirectUrl = oidcLogoutUrl;
          isRedirect = true;
        }
      } else {
        await Auth.signOut({ global: true });
      }
      tracker.markFetchRequestEnd();
    } catch (error) {
      tracker.noticeError(error);
    }

    if (isRedirect) {
      window.location.assign(redirectUrl);
    } else {
      // setting this triggers a react state refresh
      // it's not necessary if we're doing a full page redirect
      setIsLoggedIn(false);
    }
  };

  const getOAuthFlowStart = async (authServer: string, flow: string) => {
    try {
      const language =
        (window.Localize && window.Localize.getLanguage()) || "en";
      const data = await oAuthApi.getOAuthFlowStart(
        authServer,
        flow,
        language.toString()
      );
      return data;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const getOAuthToken = async (
    oAuthFlowRequest: OAuthFlowStart,
    code: string,
    state: string
  ) => {
    try {
      const data = await oAuthTokenApi.getOAuthToken(
        oAuthFlowRequest,
        code,
        state
      );
      return data;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Shared logic to create an account through the API
   * @private
   */
  const _createAccountInApi = async (
    email_address: string,
    password: string,
    role_description: ValuesOf<typeof RoleDescription>,
    deviceContext: string
  ) => {
    errorsLogic.clearErrors();
    const trimmedEmail = email_address.trim();

    // DFP Implementation
    if (isFeatureEnabled("enableDFPCreateAccount")) {
      const requestDataCreateAccount = {
        email_address: trimmedEmail,
        device_context_id: deviceContext,
        risk_assessment_type: FraudProtectionType.createAccount,
        locale: "en",
      };

      const response = await fraudProtectionApi.assessRisk(
        requestDataCreateAccount
      );

      if (response?.fraud?.decision) {
        const requestDataCreateAccountStatus = {
          risk_assessment_type: FraudProtectionType.createAccountStatus,
          status_type: statusTypes.get(response.fraud.decision),
          sign_up_id: response?.fraud.sign_up_id,
          locale: "en",
          email_address: trimmedEmail,
          device_context_id: deviceContext,
          challenge_type: "None",
          reason_type: "None",
        };
        await fraudProtectionApi.assessRisk(requestDataCreateAccountStatus);
      }

      if (
        response?.fraud.decision === FraudDecisionType.reject &&
        isFeatureEnabled("enableDFPCreateAccountRejectAction")
      ) {
        errorsLogic.catchError(
          new DFPRejectError({
            type: "dfpRejectAction",
            namespace: "dfp",
          })
        );

        return;
      }
    }

    const requestData = {
      email_address: trimmedEmail,
      password,
      role: { role_description },
    };

    try {
      await usersApi.createUser(requestData);
    } catch (error) {
      errorsLogic.catchError(error);
      return;
    }

    // Store the username so the user doesn't need to reenter it on the Verify page
    setAuthData({
      createAccountUsername: trimmedEmail,
      createAccountFlow:
        role_description === RoleDescription.employer ? "employer" : "claimant",
    });

    portalFlow.goToNextPage();
  };

  /**
   * Sets the current user as logged in, and redirects them to the next page.
   * @param [next] Redirect url after login
   * @param [shouldSetMFA] Should a user be redirected to set up MFA?
   * @private
   */
  function finishLoginAndRedirect(next?: string, shouldSetMFA?: boolean) {
    setIsLoggedIn(true);

    if (shouldSetMFA) {
      portalFlow.goToPageFor("ENABLE_MFA");
    } else if (next) {
      portalFlow.goTo(next);
    } else {
      portalFlow.goToNextPage();
    }
  }

  /**
   * Create Portal account with the given username (email) and password.
   * If there are any errors, set app errors on the page.
   */
  const createAccount = async (
    username = "",
    password: string,
    repeatPassword: string,
    deviceContext: string
  ) => {
    const validationIssues = combineValidationIssues(
      validateRepeatPassword(password, repeatPassword)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }
    await _createAccountInApi(
      username,
      password,
      RoleDescription.claimant,
      deviceContext
    );
  };

  /**
   * Create Employer Portal account with the given username (email), password, and employer ID number.
   * If there are any errors, set app errors on the page.
   */
  const createEmployerAccount = async (
    username = "",
    password: string,
    repeatPassword: string,
    deviceContext: string
  ) => {
    const validationIssues = combineValidationIssues(
      validateRepeatPassword(password, repeatPassword)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }
    await _createAccountInApi(
      username,
      password,
      RoleDescription.employer,
      deviceContext
    );
  };

  /**
   * Check current session for current user info. If user is logged in,
   * set isLoggedIn to true or false depending on whether the user is logged in.
   * If the user is not logged in, redirect the user to the login page.
   */
  const requireLogin = async () => {
    let tempIsLoggedIn = isLoggedIn;
    if (isLoggedIn === null) {
      // Check if the user is logged in with OAuth token
      tempIsLoggedIn = oidc.isOIDCAuthenticated();

      // Fall back to Cognito/Amplify state
      if (!tempIsLoggedIn) {
        const cognitoUserInfo = await Auth.currentUserInfo();
        tempIsLoggedIn = !!cognitoUserInfo;
      }

      setIsLoggedIn(tempIsLoggedIn);
    }

    assert(tempIsLoggedIn !== null);

    // TODO (CP-733): Update this comment once we move logout functionality into this module
    // Note that although we don't yet have a logout function that sets isLoggedIn to false,
    // the logout (signOut) functionality in AuthNav.js forces a page reload which will
    // reset React in-memory state and set isLoggedIn back to null.

    if (tempIsLoggedIn) return;
    if (!tempIsLoggedIn && !portalFlow.pathname.match(routes.auth.login)) {
      const { pathWithParams } = portalFlow;

      portalFlow.goTo(routes.auth.login, { next: pathWithParams });
    }
  };

  const resendVerifyAccountCode = async (username = "") => {
    errorsLogic.clearErrors();
    const trimmedUsername = username.trim();

    const validationIssues = combineValidationIssues(
      validateUsername(trimmedUsername)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    try {
      tracker.trackAuthRequest("resendSignUp");
      await Auth.resendSignUp(trimmedUsername);
      tracker.markFetchRequestEnd();

      // TODO (CP-600): Show success message
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }

      errorsLogic.catchError(new CognitoAuthError(error));
    }
  };

  /**
   * Use a verification code to confirm the user is who they say they are
   * and allow them to reset their password
   */
  const resetPassword = async (
    username = "",
    code = "",
    password = "",
    repeatPassword = ""
  ) => {
    errorsLogic.clearErrors();

    const trimmedUsername = username.trim();
    const trimmedCode = code.trim();

    const validationIssues = combineValidationIssues(
      validateCode(trimmedCode),
      validateUsername(trimmedUsername),
      validatePassword(password),
      validateRepeatPassword(password, repeatPassword)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    await resetPasswordInCognito(trimmedUsername, trimmedCode, password);
  };

  /**
   * Use a verification code to confirm the user is who they say they are
   * and allow them to reset their password
   * @private
   */
  const resetPasswordInCognito = async (
    username = "",
    code = "",
    password = ""
  ) => {
    try {
      tracker.trackAuthRequest("forgotPasswordSubmit");
      await Auth.forgotPasswordSubmit(username, code, password);
      tracker.markFetchRequestEnd();

      portalFlow.goToNextPage();
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }

      const authError = getResetPasswordError(error);
      errorsLogic.catchError(authError);
    }
  };

  /**
   * Shared logic to verify an account
   * @private
   */
  const verifyAccountInCognito = async (username = "", code = "") => {
    try {
      tracker.trackAuthRequest("confirmSignUp");
      await Auth.confirmSignUp(username, code);
      tracker.markFetchRequestEnd();

      portalFlow.goToNextPage(
        {},
        {
          "account-verified": "true",
        }
      );
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }

      // If the error is the user trying to re-verified an already-verified account then we can redirect
      // them to the login page. This only occurs if the user's account is already verified and the
      // verification code they use is valid.
      if (
        error.code === "NotAuthorizedException" &&
        error.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        portalFlow.goToNextPage(
          {},
          {
            "account-verified": "true",
          }
        );
      }

      const authError = getVerifyAccountError(error);
      errorsLogic.catchError(authError);
    }
  };

  /**
   * Verify Portal account with the one time verification code that
   * was emailed to the user. If there are any errors, set app errors
   * on the page.
   */
  const verifyAccount = async (username = "", code = "") => {
    errorsLogic.clearErrors();

    const trimmedUsername = username.trim();
    const trimmedCode = code.trim();

    const validationIssues = combineValidationIssues(
      validateCode(trimmedCode),
      validateUsername(trimmedUsername)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    await verifyAccountInCognito(trimmedUsername, trimmedCode);
  };

  return {
    authData,
    cognitoUser,
    createAccount,
    createEmployerAccount,
    enableTrustDevice,
    forgotPassword,
    getOAuthFlowStart,
    getOAuthToken,
    login,
    logout,
    isCognitoError,
    isLoggedIn,
    isPhoneVerified,
    requireLogin,
    resendVerifyAccountCode,
    resetPassword,
    resendForgotPasswordCode,
    verifyAccount,
    verifyMFACodeAndLogin,
  };
};

/**
 * Converts an error thrown by the Amplify library's Auth.forgotPassword method into
 * CognitoAuthError.
 * For a list of possible exceptions, see
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html#API_ForgotPassword_Errors
 */
function getForgotPasswordError(error: CognitoError) {
  let issue;
  const errorCodeToIssueMap: ErrorCodeMap = {
    CodeDeliveryFailureException: { field: "code", type: "deliveryFailure" },
    InvalidParameterException: { type: "invalidParametersFallback" },
    UserNotFoundException: { type: "userNotFound" },
    LimitExceededException: { type: "attemptsLimitExceeded_forgotPassword" },
  };

  if (error.code === "NotAuthorizedException") {
    issue = getNotAuthorizedExceptionIssue(error, "forgotPassword");
  } else if (errorCodeToIssueMap[error.code]) {
    issue = { ...errorCodeToIssueMap[error.code], namespace: "auth" };
  }

  return new CognitoAuthError(error, issue);
}

/**
 * Converts an error thrown by the Amplify library's Auth.forgotPasswordSubmit method into
 * CognitoAuthError.
 * For a list of possible exceptions, see
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
 */
function getResetPasswordError(error: CognitoError) {
  let issue;
  const errorCodeToIssueMap: ErrorCodeMap = {
    CodeMismatchException: { field: "code", type: "mismatchException" },
    ExpiredCodeException: { field: "code", type: "expired" },
    InvalidParameterException: {
      type: "invalidParametersIncludingMaybePassword",
    },
    UserNotConfirmedException: { type: "userNotConfirmed" },
    UserNotFoundException: { type: "userNotFound" },
  };

  if (errorCodeToIssueMap[error.code]) {
    issue = { ...errorCodeToIssueMap[error.code], namespace: "auth" };
  } else if (error.code === "InvalidPasswordException") {
    issue = getInvalidPasswordExceptionIssue(error);
  }

  return new CognitoAuthError(error, issue);
}

/**
 * Converts an error thrown by the Amplify library's Auth.confirmSignUp method into
 * CognitoAuthError.
 * For a list of possible exceptions, see
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
 */
function getVerifyAccountError(error: CognitoError) {
  let issue;
  const errorCodeToIssueMap: ErrorCodeMap = {
    CodeMismatchException: { field: "code", type: "mismatchException" },
    ExpiredCodeException: { field: "code", type: "expired" },
  };

  if (errorCodeToIssueMap[error.code]) {
    issue = { ...errorCodeToIssueMap[error.code], namespace: "auth" };
  }

  return new CognitoAuthError(error, issue);
}

/**
 * InvalidPasswordException may occur for a variety of reasons,
 * so our errors needs to reflect this nuance.
 */
function getInvalidPasswordExceptionIssue(error: CognitoError): Issue {
  // These are the specific Cognito errors that can occur:
  //
  // 1. When password is less than 6 characters long
  // code: "InvalidParameterException"
  // message: "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
  //
  // 2. When password is between 6 and 8 characters long
  // code: "InvalidPasswordException"
  // message: "Password did not conform with policy: Password not long enough"
  //
  // 3. When password does not have lower case characters
  // code: "InvalidPasswordException"
  // message: "Password did not conform with policy: Password must have uppercase characters"
  //
  // 4. When password does not have upper case characters
  // code: "InvalidPasswordException"
  // message: "Password did not conform with policy: Password must have lowercase characters"
  //
  // 5. When password has both upper and lower case characters but no digits
  // code: "InvalidPasswordException"
  // message: "Password did not conform with policy: Password must have numeric characters"
  //
  // 6. When password is a commonly used or compromised credential
  // code: "InvalidPasswordException"
  // message: "Provided password cannot be used for security reasons."

  if (error.message.match(/password cannot be used for security reasons/)) {
    // For this case, a password may already conform to the password format
    // requirements, so showing the password format error would be confusing
    return { field: "password", type: "insecure", namespace: "auth" };
  }

  return { field: "password", type: "invalid", namespace: "auth" };
}

export default useAuthLogic;
export type AuthLogic = ReturnType<typeof useAuthLogic>;
